import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Investments from "../../components/body/pages/investments"

const InvestmentsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/investments/"}
      title="Investments | Invest in US Stocks & Fractional Shares | Kuda"
      description="With Kuda you can invest in US stocks in Nigeria. It's easy to add USD to your account and buy fractional shares to invest in your future."
    />
    <Investments />
  </Layout>
)

export default InvestmentsPage
