import React, { useEffect, Fragment } from "react"

import { scrollToElement } from "../../../utility/utils"
import CTAInvestments from "../general/ctaInvestments";
import BelowFoldCards from "../general/belowFoldCards"
import AnimationTextLeft from "../general/animationTextLeft"
import InvestmentIllustration from "../../../../assets/img/kuda-investment.inline.svg";
import SellSharesIllustration from "../../../../assets/img/kuda-sell-shares-illustration.inline.svg";
import NotificationsIllustration from "../../../../assets/img/kuda-notifications-investment-illustration.inline.svg";
import WithdrawIcon from "../../../../assets/img/dollar.inline.svg"
import NairaIcon from "../../../../assets/img/naira.inline.svg"
import Money from "../../../../assets/img/dollars.inline.svg"
import Projection from "../../../../assets/img/projection.inline.svg"
import HelpIcon from "../../../../assets/img/global/help.inline.svg"
import PhoneBlur from "../../../../assets/img/circle.inline.svg"

import ImageTextLeft from "../general/imageTextLeft"
import ImageTextRight from "../general/imageTextRight"
import BadgesEntryFold from "../general/badgesEntryFold";
import MoreForYouComponent from "../general/more-for-you";

import TransferIcon from '../../../../assets/img/more-for-you/kuda-transfer.inline.svg'
import KudaSaveIcon from '../../../../assets/img/more-for-you/kuda-save-icon.inline.svg'
import BorrowIcon from '../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg'

const entryContent = {
  title: "Invest in US stocks at affordable prices.",
  subtitle: "We’ve made it easy for anyone with a Kuda account to buy US stocks with their naira.",
  disclaimerText:"Stock prices fluctuate and trading involves possible loss of capital.",
  illustration: <InvestmentIllustration/>,
}


const buyShares = {
  title: "We’ve simplified buying US stocks for you.",
  subtitle:    "Create a trading account on your Kuda app, explore stocks and buy whichever you like whenever you want to.",
  url: "https://help.kuda.com/support/solutions/articles/73000595512-about-investments-on-kuda",
  name: "Learn more about buying stocks on Kuda",
  animation: "invest-money",
  className: "transform-right",
}

const sellShares = {
  title: "Your money-making options now include earning dividends and selling stocks.",
  subtitle:
    "Make a passive income from dividends paid directly into your trading account or sell your stocks at your convenience.",
   url: "https://help.kuda.com/support/solutions/articles/73000595638-depositing-and-withdrawing",
  name: "Learn more about selling stocks on Kuda",
  illustration: <SellSharesIllustration />,
}


const notifications = {
  title: "We’ll show you up-to-date stock prices and send you trade notifications for your peace of mind.",
  subtitle: "See current stock prices on your Kuda app and get an instant alert for every activity on your trading account.",
   url: "https://kuda.onelink.me/abUI/344e3dde/",
  name: "Join Kuda",
  illustration: <NotificationsIllustration />,
}
const kudaTopInvestments = [
  {
    icon: <WithdrawIcon />,
    text: "Explore a wide range of US stocks",
  },
  {
    icon: <NairaIcon />,
    text: "Buy stocks easily with your naira.",
  },
  {
    icon: <Projection />,
    text: "Get up-to-date stock prices and trade notifications.",
  },
];

const kudaBottomInvestments = [
  {
    icon: <Money />,
    text: "Earn dividends on well-performing stocks.",
  },
];

const moreForYou = [

  {
    icon: <KudaSaveIcon />,
    title: "Save",
    subText: `Make saving easier with Spend+Save, Pocket or Fixed savings with up to 12% annual interest.`,
    linkTo: `/en-ng/save/`
  },
  {
    icon: <BorrowIcon />,
    title: "Loans",
    subText: `Get up to ₦150,000 in your Kuda account easily and repay in convenient instalments.`,
    linkTo: `/en-ng/personal-loan/`
  },
  {
    icon: <TransferIcon />,
    title: "Transfer & Spend",
    subText: "Send money for free to any Nigerian account with 25 free transfers every month.",
    linkTo: `/en-ng/spend/`
  },
]

const Investments = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <BadgesEntryFold
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
        disclaimerText={entryContent.disclaimerText}
      />
      <BelowFoldCards topFeatures={kudaTopInvestments} bottomFeatures={kudaBottomInvestments} />
      
      <AnimationTextLeft
        title={buyShares.title}
        subtitle={buyShares.subtitle}
        fallBack={<PhoneBlur className="blur" />}
        animationUrl={buyShares.animation}
        buttonName={buyShares.name}
        buttonUrl={buyShares.url}
        isExternal={true}
        image={<HelpIcon />}
        rightClassName={buyShares.className}
      />
      <ImageTextRight
        title={sellShares.title}
        subtitle={sellShares.subtitle}
        illustration={sellShares.illustration}
        name={sellShares.name}
        url={sellShares.url}
        isExternal={true}
      />    
      <ImageTextLeft
        title={notifications.title}
        subtitle={notifications.subtitle}
        illustration={notifications.illustration}
        name={notifications.name}
        url={notifications.url}
        isExternal={true}
        isWebButton={true}
        disclamerText={"Stock prices fluctuate and trading involves possible loss of capital."}
      />
      <MoreForYouComponent moreForYou={moreForYou} />
      <CTAInvestments/>
    </Fragment>
  )
}

export default Investments